<template>
  <div id="canvasChart" class="chart">
    <div class="chart-container-all">
      <div class="chart-main" id="chartMain">
        <div id="canvasContainer">
          <canvas id="flightCanvas"></canvas>
          <div class="time-line start-time" id="startTime">{{ startTime }}</div>
          <div class="time-line end-time" id="endTime">{{ endTime }}</div>

          <div class="unit-cn-ht">高度(m)</div>
          <div class="unit-cn-spd">速度(m/s)</div>

          <div class="spdHt-line" id="line" :style="{ left: lineLeft }">
            <span class="spdHt-text text-green">
              <div>
                <span id="ht">{{ curentAltitude }}</span>
              </div>
            </span>
            <span class="spdHt-text text-yellow">
              <div>
                <span id="spd">{{ currentHorizontalSpeed }}</span>
              </div>
            </span>
            <span class="text-time">
              <div>
                <span id="time">{{ realTimeShow }}</span>
              </div>
            </span>
          </div>
        </div>
        <div id="slider">
          <el-slider v-model="sliderVal" :max="records.length > 0 ? records.length - 1 : 0" @change="handlerChange" :format-tooltip="formatTooltip"></el-slider>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CanvasChart',
  props: {
    records: {
      type: Array,
      default() {
        return [];
      },
    },
    current: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  data() {
    return {
      sliderVal: 0,
      maxHt: 350,
      maxSpd: 20,
      lineLeft: '0px',
    };
  },
  watch: {
    current: {
      handler(n, o) {
        this.sliderVal = n;
        this.renderCanvas();
      },
    },
    records: {
      handler(n, o) {
        let arr = this.caculateMaxHtAndMaxSpd();
        if (arr) {
          this.maxHt = arr[0];
          this.maxSpd = arr[1];
          this.renderCanvas();
        }
      },
    },
  },
  mounted() {
    let _this = this;
    _this.sliderVal = _this.current;
    _this.$nextTick(() => {
      _this.renderCanvas();
    });
  },

  computed: {
    startTime() {
      if (this.records.length > 0) return this.$moment(this.records[0].timestamp).format('HH:mm:ss');
      return '';
    },
    endTime() {
      if (this.records.length > 0) return this.$moment(this.records[this.records.length - 1].timestamp).format('HH:mm:ss');
      return '';
    },
    curentAltitude() {
      if (this.records.length > 0 && this.records[this.current]) return this.records[this.current].altitude + 'm';
      return '';
    },
    currentHorizontalSpeed() {
      if (this.records.length > 0 && this.records[this.current]) return this.records[this.current].horizontalSpeed + 'm/s';
      return '';
    },
    realTimeShow() {
      if (this.records.length > 0 && this.records[this.current]) return this.$moment(this.records[this.current].timestamp).format('HH:mm:ss');
      return '';
    },
  },
  methods: {
    //格式化显示的文字
    formatTooltip(val) {
      if (this.records.length > 0) return this.$moment(this.records[val].timestamp).format('HH:mm:ss');
      return '';
    },
    // 滑块改变的值
    handlerChange(value) {
      this.$emit('change', value);
    },
    caculateMaxHtAndMaxSpd() {
      let records = this.records;
      if (records.length == 0) return null;
      let maxHt = records[0].altitude;
      let maxSpd = records[0].horizontalSpeed;
      if (records.length > 1) {
        for (let i = 1; i < records.length; i++) {
          if (maxHt < records[i].altitude) maxHt = records[i].altitude;
          if (maxSpd < records[i].horizontalSpeed) maxSpd = records[i].horizontalSpeed;
        }
      }
      maxHt = parseInt(maxHt) + (5 - (parseInt(maxHt) % 5));
      maxSpd = parseInt(maxSpd) + (5 - (parseInt(maxSpd) % 5));
      return [maxHt, maxSpd];
    },
    renderCanvas() {
      let maxHt = this.maxHt;
      let maxSpd = this.maxSpd;
      let titleHeight = 30;
      let canvaspaddinglr = 0;
      let canvaspaddingtb = 0;
      let htleftoffset = 20;
      let spdrightoffset = 20;
      let fontht = 5;
      let kdwidth = 5;
      let htColor = '#0cef0a';
      let spdColor = 'yellow';
      let w = document.getElementById('canvasContainer').offsetWidth;
      let h = document.getElementById('canvasContainer').offsetHeight;
      let canvas = document.getElementById('flightCanvas');
      let count = this.records.length;
      // 右边底部画轨迹
      let basex = canvaspaddinglr;
      // let basey = h - 30;
      let basey = h;
      let xtotal = w - canvaspaddinglr * 2;
      let ytotal = h - titleHeight;
      let xunit = xtotal; // 1m 的高度对应的像素
      if (count > 1) xunit = xtotal / (count - 1); // 1m 的高度对应的像素
      let yunitHt = ytotal / (maxHt - 1); //1m 的高度对应的像素
      let yunitSpd = ytotal / (maxSpd - 1); //1m/s 的速度对应的像素
      canvas.width = w;
      canvas.height = h;
      let ctx = canvas.getContext('2d');
      ctx.beginPath();
      ctx.fillStyle = htColor;
      ctx.textAlign = 'center';
      ctx.strokeStyle = '#0cef0a';
      ctx.lineWidth = 1;
      //左边
      ctx.moveTo(canvaspaddinglr, canvaspaddingtb);
      ctx.lineTo(canvaspaddinglr, h - canvaspaddingtb);
      ctx.stroke();
      // ctx.fillText("高度(m)", canvaspaddinglr + 30, 10);
      // 左边刻度
      for (let i = 0; i <= 5; i++) {
        ctx.moveTo(canvaspaddinglr, h - canvaspaddingtb - (ytotal / 5) * i);
        ctx.fillText(i * (maxHt / 5), canvaspaddinglr + htleftoffset, h - canvaspaddingtb - (ytotal / 5) * i + fontht);
        ctx.lineTo(canvaspaddinglr + kdwidth, h - canvaspaddingtb - (ytotal / 5) * i);
        ctx.stroke();
      }
      ctx.closePath();
      ctx.beginPath();
      // 右边
      ctx.moveTo(w - canvaspaddinglr, canvaspaddingtb);
      ctx.lineTo(w - canvaspaddinglr, h - canvaspaddingtb);
      ctx.stroke();
      ctx.fillStyle = spdColor;
      ctx.strokeStyle = spdColor;
      //   ctx.fillText("速度(m/s)", w - (canvaspaddinglr + 30), 10);
      // 右边刻度
      for (let i = 0; i <= 5; i++) {
        ctx.moveTo(w - canvaspaddinglr, h - canvaspaddingtb - (ytotal / 5) * i);
        ctx.fillText(i * (maxSpd / 5), w - canvaspaddinglr - spdrightoffset, h - canvaspaddingtb - (ytotal / 5) * i + fontht);
        ctx.lineTo(w - canvaspaddinglr - kdwidth, h - canvaspaddingtb - (ytotal / 5) * i);
        ctx.stroke();
      }
      ctx.closePath();
      ctx.beginPath();
      let index = this.current;
      // 绘制轨迹
      if (index > 0) {
        // 绘制高度轨迹
        ctx.moveTo(basex, basey - yunitHt * this.records[0].altitude);
        ctx.strokeStyle = '#0cef0a';
        for (let i = 1; i <= index; i++) {
          let record = this.records[i];
          ctx.lineTo(basex + xunit * i, basey - yunitHt * record.altitude);
        }
        // 回到原点，准备填充颜色
        ctx.lineTo(basex + xunit * index, basey);
        ctx.lineTo(0, basey);

        ctx.stroke();
        ctx.save();
        ctx.closePath();

        // 绘制背景色
        //创建一个从(150,0)到(250,0)的渐变
        var gradient = ctx.createLinearGradient(0, 0, basex, basey);
        //添加两种颜色
        gradient.addColorStop(0, '#09ae0880'); //#09aa0899 #0cef0a
        gradient.addColorStop(1, 'black');
        //填充渐变色
        ctx.fillStyle = gradient;
        ctx.fill();
        ctx.stroke();
        ctx.beginPath();
        // 绘制速度轨迹
        ctx.moveTo(basex, basey - yunitSpd * this.records[0].horizontalSpeed);
        ctx.strokeStyle = '#fefd30';
        for (let i = 1; i <= index; i++) {
          let record = this.records[i];
          ctx.lineTo(basex + xunit * i, basey - yunitSpd * record.horizontalSpeed);
        }
        ctx.stroke();
        ctx.save();
        ctx.closePath();
      }
      let unit = (w - canvaspaddinglr * 2) / (count - 1);
      let px = basex + index * unit;
      this.lineLeft = px + 'px';
    },
  },
};
</script>

<style lang="scss" scoped>
$titleHeight: 30px;
$sliderHeight: 30px;
$timeShowHeight: 20px;
.chart {
  width: 100%;
  height: 100%;
  padding: 0 35px 20px 50px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .chart-container-all {
    width: 100%;
    height: calc(100% - #{$titleHeight});
    margin-top: $titleHeight;
    position: relative;
    .chart-main-time {
      width: 100%;
      height: $timeShowHeight;
      position: absolute;
      bottom: 0;
    }
    .chart-main {
      position: absolute;
      width: 100%;
      height: calc(100% - #{$timeShowHeight});
      top: 0;
      #slider {
        position: absolute;
        bottom: -10px;
        left: 0;
        width: 100%;
        height: $sliderHeight;
      }
      #canvasContainer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        .time-line {
          width: fit-content;
          position: absolute;
          bottom: -24px;

          color: white;
        }
        .start-time {
          left: -20px;
        }
        .end-time {
          right: -20px;
        }
        .unit-cn-ht {
          position: absolute;
          top: -24px;
          left: -15px;
          color: #29ea21;
        }
        .unit-cn-spd {
          position: absolute;
          top: -24px;
          right: -15px;
          color: yellow;
        }
        .flightReplayGraphContainer {
          position: relative;
          width: 100%;
          height: 100%;
          direction: ltr;
        }
        .spdHt-line {
          position: absolute;
          width: 2px;
          height: 100%;
          bottom: 0;
          left: 0px;
          background: #409eff;
        }
        .spdHt-text {
          position: absolute;
          color: #28ea0d;
          top: 0px;
        }
        .text-green {
          right: 10px;
        }
        .text-yellow {
          left: 10px;
          color: yellow;
        }
        .text-time {
          position: absolute;
          color: white;
          bottom: -35px;
          left: -20px;
        }
      }
    }
  }
}
</style>
