
<template>
    <div class="flightIndicator">
        <span :id="id"></span>
        <span v-if="showText" class="instrument-type" :style="{color:fontColor}">
            滚转角:{{roll}}° , 俯仰角:{{pitch}}°
        </span>
    </div>
</template>

<script>
import $ from '@/utils/jquery-v.js';
import flightIndicator from '@/utils/jquery.flightindicators.min.js';
export default {
    name: 'RollPitch',
    props: {
        size: {
            type: Number,
            default() {
                return 200;
            },
        },
        id: {
            type: String,
            default() {
                return ' attitude'
            }
        },
        fontColor: {
            type: String,
            default() {
                return ' #333'
            }
        },
        showText: {
            type: Boolean,
            default() {
                return true
            }
        },
        roll: {
            type: Number,
            default() {
                return 0;
            },
        },
        pitch: {
            type: Number,
            default() {
                return 0;
            },
        }
    },
    data() {
        return {
            attitude: null
        };
    },
    mounted() {
        this.state();
    },
    methods: {
        state() {
            this.attitude = $.flightIndicator(`#${this.id}`, 'attitude', { roll: 0, pitch: 0, size: this.size, showBox: false });
        },
    },
    watch: {
        roll(newVal, oldVal) {
            this.attitude.setRoll(newVal);
        },
        pitch(newVal, oldVal) {
            this.attitude.setPitch(newVal);
        },
        size(newVal) {
            if (this.attitude) {
                this.attitude.resize(newVal)
            }
        }
    },
    computed: {},
};
</script>

<style lang="scss" scoped>
.flightIndicator {
    position: relative;
    .instrument-type {
        position: absolute;
        left: 44px;
        bottom: -10px;
        font-weight: 600;
    }
}
</style>
