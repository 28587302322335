import axios from 'axios';
import { base_url } from '@/utils/BASE_URL';

const server = base_url.server
// 正式环境下载视频
export const VIDEOWNLOAD = `${server}/media/OssVideo/downloadRecordVideo`;
export const VIDEOCATCH = `${server}/media/OssVideo/realtimeRecordVideo`;

export function downloadByUrl(url, name) {
  let aTag = document.createElement('a');
  aTag.href = url; //绑定a标签
  aTag.rel = 'noflollow noreferrer friend'; //
  aTag.download = name ? name : new Date().toString();
  document.body.appendChild(aTag);
  aTag.click(); //模拟点击实现下载

  setTimeout(function () {
    document.body.removeChild(aTag);
  }, 500);
}

export function downloadRecordVideo(missionBatch, name = 'download', callback) {
  let params = {
    missionBatch: missionBatch,
  };
  axios
    .get(VIDEOWNLOAD, {
      params: params,
      responseType: 'blob',
    })
    .then((res) => {
      if (callback) {
        callback();
      }
      if (res.data) {
        // 转换一个blob链接
        let u = window.URL.createObjectURL(new Blob([res.data], { type: 'video/mp4' }));
        let a = document.createElement('a');
        a.download = name; //这里是文件名称，这里暂时用链接代替，可以替换
        a.href = u;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        a.remove();
        return true;
      }
    });
}
