
<template>
    <div class="flightIndicator">
        <span :id="id"></span>
        <span v-if="showText" class="instrument-type" :style="{color:fontColor}">偏航角 : {{value}}°</span>
    </div>
</template>

<script>
import $ from '@/utils/jquery-v.js';
import flightIndicator from '@/utils/jquery.flightindicators.min.js';
export default {
    name: 'Heading',
    props: {
        id: {
            type: String,
            default() {
                return ' attitude'
            }
        },
        value: {
            type: Number,
            default() {
                return 0;
            },
        },
        size: {
            type: Number,
            default() {
                return 170;
            },
        },
        fontColor: {
            type: String,
            default() {
                return ' #333'
            }
        },
        showText: {
            type: Boolean,
            default() {
                return true
            }
        },
    },
    data() {
        return {
            heading: null,
        };
    },
    mounted() {

        this.state();
    },
    methods: {
        state() {
            this.heading = $.flightIndicator(`#${this.id}`, 'heading', {
                heading: this.value,
                size: this.size,
                showBox: false,
            });
            this.heading.setHeading(0);
        },
    },
    watch: {
        value(newVal, oldVal) {
            this.heading.setHeading(newVal);
        },
        size(newVal) {
            if (this.heading) {
                this.heading.resize(newVal)
            }
        }
    },
    computed: {},
};
</script>

<style lang="scss" scoped>
.flightIndicator {
    position: relative;
    .instrument-type {
        position: absolute;
        left: 58px;
        bottom: -10px;
        font-weight: 600;
    }
}
</style>
