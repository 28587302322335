<template>
  <div class="framework-wrapper">
    <page-top></page-top>
    <div class="page-container">
      <div class="left" :style="leftStyle">
      
        <div class="top-left" :style="topLeftStyle">
         <!-- 二维、三维切换 -->
        <!-- <div class="three-D">
          <el-tooltip class="item" effect="dark" :content="is3DMode ? '切换为2D' : '切换为3D'">
            <img :title="is3DMode ? '切换2D模式'.two : '切换3D模式'" :src="is3DMode ? mapModeImg.two : mapModeImg.three" @click="is3DMode = !is3DMode" />
          </el-tooltip>
        </div> -->
            <!-- cesium 地图 -->
        <cesium-map style="position:absolute;top:0;left:0;z-index: 999;" v-if="is3DMode" ref="cesiumMap" :site="siteInfo"  :uavPos="uavPosFor3D" :pts="route3D" :records="records3D" @twoD="is3DMode=false" />
                
          <ol-map :site="siteInfo" style="position:absolute;top:0;left:0" ref="map" @postrender="init" @threeD="is3DMode=true"></ol-map>
        </div>
        <div class="top-right" id="videoContainer" :style="topRightStyle">
          <div v-if="lazyShow" class="no-source">
            <div>
              <p>暂无视频资源</p>
            </div>
          </div>
          <video v-else class="video" id="video" ref="videoRef" preload="true"></video>
        </div>
        <div class="bottom-left" :style="bottomLeftStyle">
          <div class="instruments">
            <div>
              <div>
                <RollPitch id="uavrollpitch" :size="layout.fightSize" :roll="cuurentRoll" :pitch="cuurentPitch" :showText="false" :fontColor="'white'" />
              </div>
              <div>
                <Heading id="uavhead" :size="layout.fightSize" :value="cuurenHeading" :showText="false" :fontColor="'white'" />
              </div>
            </div>

            <div style="margin-top: 10px; color: white">
              <div>滚转角：{{ cuurentRoll }}° ，</div>
              <div>俯仰角：{{ cuurentPitch }}° ，</div>
              <div>偏航角：{{ cuurenHeading }}°</div>
            </div>

            <div class="attitude-title" style="margin: 10px auto">无人机姿态仪表</div>

            <div class="card" style="flex-direction: column">
              <div class="title">流程信息</div>
              <div class="progress">
                <div class="progress-main" :style="{ width: processWidth }">
                  <el-steps align-center :space="space" :active="activeEvent">
                    <el-step v-for="(item, index) in events" :key="index" :title="item.label"></el-step>
                  </el-steps>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom-right" :style="bottomRightStyle">
          <div class="set-rate">
            <div>
              <control-item @controller="controllerHandler" @videoTimeChange="handleVideoTimeChange" ref="controlBar" :playing="isPlaying"></control-item>
            </div>
          </div>
          <CanvasChart :records="records" :current="currentIndex" @change="updateBySlider" ref="canvasChart" />
        </div>
      </div>
      <div class="right" :style="rightStyle">
        <div class="card">
          <div class="title">任务基本信息</div>
          <div class="item-one">
            <div>任务名称：{{ this.missionExecutionInformation.missionName.value }}</div>
          </div>
          <div class="item-one">
            <div>站点名称：{{ this.missionExecutionInformation.siteName.value }}</div>
          </div>
          <div class="item-one">
            <div>任务批次：{{ this.missionExecutionInformation.missionBatch.value }}</div>
          </div>
          <div class="item-one">
            <div>开始时间：{{ this.missionExecutionInformation.UAVStartTime.value }}</div>
          </div>
          <div class="item-one">
            <div>结束时间： {{ this.missionExecutionInformation.UAVEndTime.value }}</div>
          </div>
          <div class="item-one">
            <div>
              <el-link type="primary" @click="dialogVisible = true">查看操作记录</el-link>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="title">无人机报文信息</div>
          <div class="row">
            <div class="item">
              <div class="img-info">
                <div class="img">
                  <img :src="infoImg.lnglat" />
                </div>
                <div class="info">
                  <div>{{ currentLongitude }}</div>
                  <div>经度</div>
                </div>
              </div>
            </div>
            <div class="item">
              <div class="img-info">
                <div class="img">
                  <img :src="infoImg.lnglat" />
                </div>
                <div class="info">
                  <div>{{ currentLatitude }}</div>
                  <div>纬度</div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="item">
              <div class="img-info">
                <div class="img">
                  <img :src="infoImg.ht" />
                </div>
                <div class="info">
                  <div>{{ currentAltitude }}</div>
                  <div>高度</div>
                </div>
              </div>
            </div>
            <div class="item">
              <div class="img-info">
                <div class="img">
                  <img :src="infoImg.distance" />
                </div>
                <div class="info">
                  <div>{{ currentDistanceStart }}</div>
                  <div>距起点距离</div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="item">
              <div class="img-info">
                <div class="img">
                  <img :src="infoImg.hspeed" />
                </div>
                <div class="info">
                  <div>{{ currentSpeedH }}</div>
                  <div>水平速度</div>
                </div>
              </div>
            </div>
            <div class="item">
              <div class="img-info">
                <div class="img">
                  <img :src="infoImg.vspeed" />
                </div>
                <div class="info">
                  <div>{{ currentSpeedV }}</div>
                  <div>垂直速度</div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="item">
              <div class="img-info">
                <div class="img">
                  <img :src="infoImg.level" />
                </div>
                <div class="info">
                  <div>{{ currentGPSLevel }}</div>
                  <div>GPS等级</div>
                </div>
              </div>
            </div>
            <div class="item">
              <div class="img-info">
                <div class="img">
                  <img :src="infoImg.gps" />
                </div>
                <div class="info">
                  <div>{{ currentGPSCount }}</div>
                  <div>GPS星数</div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="item">
              <div class="img-info">
                <div class="img">
                  <img :src="infoImg.upsignal" />
                </div>
                <div class="info">
                  <div>{{ currentUpLink }}</div>
                  <div>上行信号</div>
                </div>
              </div>
            </div>
            <div class="item">
              <div class="img-info">
                <div class="img">
                  <img :src="infoImg.downsignal" />
                </div>
                <div class="info">
                  <div>{{ currentDownLink }}</div>
                  <div>下行信号</div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="item">
              <div class="img-info">
                <div class="img">
                  <img :src="infoImg.charge" />
                </div>
                <div class="info">
                  <div>{{ currentBattery1Percent }}</div>
                  <div>电量</div>
                </div>
              </div>
            </div>
            <div class="item">
              <div class="img-info">
                <div class="img">
                  <img :src="infoImg.temp" />
                </div>
                <div class="info">
                  <div>{{ currentBattery1Temperature }}</div>
                  <div>温度</div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="item">
              <div class="img-info">
                <div class="img">
                  <img class="spec" :src="infoImg.gimbalpitch" />
                </div>
                <div class="info">
                  <div>{{ cuurentGimbalPitch }}°</div>
                  <div>云台俯仰角</div>
                </div>
              </div>
            </div>
            <div class="item">
              <div class="img-info">
                <div class="img">
                  <img class="spec" :src="infoImg.gimbalroll" />
                </div>
                <div class="info">
                  <div>{{ cuurentGimbalRoll }}°</div>
                  <div>云台滚转角</div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="item">
              <div class="img-info">
                <div class="img">
                  <img class="spec" :src="infoImg.gimbalyaw" />
                </div>
                <div class="info">
                  <div>{{ cuurentGimbaYaw }}°</div>
                  <div>云台偏航角</div>
                </div>
              </div>
            </div>
            <div class="item">
              <div class="img-info">
                <div class="img">
                  <img class="spec" :src="infoImg.big" style="width: 30px; height: 30px; margin-top: 0px; margin-left: 0px" />
                </div>
                <div class="info">
                  <div>{{ cuurentZoom }}</div>
                  <div>放大倍率</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog :close-on-click-modal="false" title="操作记录" :visible.sync="dialogVisible" width="30%">
      <div class="operater-data" v-if="operaters.length > 0">
        <div v-for="(item, index) in operatersFilter" :key="'operaters' + index">
          <div>
            <span>{{ item.time }}</span>
            ：
            <span>{{ item.content }}</span>
          </div>
        </div>
      </div>
      <div class="no-data" v-else>无操作记录</div>
    </el-dialog>
    <el-dialog title="视频下载进度" :visible.sync="downloading" width="30%" :lock-scroll="false" :close-on-click-modal="false">
      <div style="margin: auto; width: 126px">
        <el-progress type="circle" :percentage="downloadPercentage"></el-progress>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import OlMap from '@/components/maps/OlMap.vue';
import CesiumMap from '@/components/maps/CesiumMap';
import PageTop from './children/pageTopBar.vue';
import CanvasChart from './children/canvasChart/index.vue';
import ControlItem from './children/controlItem.vue';
import Heading from '@/components/Flightlndicator/heading.vue';
import RollPitch from '@/components/Flightlndicator/rollPitch.vue';
import { getBatchinfo } from '@/api/flight';
import { getSiteDetail } from '@/api/site';
import { VIDEOWNLOAD } from '@/api/download.js';
import parseUAVData from '@/utils/parseUAVData.js';
import axios from 'axios';
import statusInclude from '@/utils/statusCode';
import {getAuthorization ,getToken } from '@/utils/auth';

export default {
  name: 'FrameWork',
  components: {
    CanvasChart,
    Heading,
    RollPitch,
    ControlItem,
    OlMap,
    CesiumMap,
    PageTop,
  },
  data() {
    return {
       is3DMode: false,
       route3D:[],
       records3D:[],
        mapModeImg: {
        two: require('@/assets/images/map/2d.png'),
        three: require('@/assets/images/map/3d.png')
      },
      dialogVisible: false,
      downloading: false,
      downloadPercentage: 0,
      axiosSource: null,
      missionBatch: null,
      siteID: null,
      UAVID: 'uavid',
      GIMID: 'gimid',
      update: 0,
      processWidth: '100%',
      siteInfo: {},
      layout: {
        sideWidth: 400,
        videoWidth: 1120,
        videoHeight: 630,
        sideWidht169Height: 225,
        fightSize: 200,
      },

      infoImg: {
        charge: require('@/assets/images/attitude/charge.png'),
        big: require('@/assets/images/attitude/big.png'),
        downsignal: require('@/assets/images/attitude/downsignal.png'),
        gps: require('@/assets/images/attitude/gps.png'),
        hspeed: require('@/assets/images/attitude/hspeed.png'),
        vspeed: require('@/assets/images/attitude/vspeed.png'),
        upsignal: require('@/assets/images/attitude/upsignal.png'),
        ht: require('@/assets/images/attitude/ht.png'),
        direction: require('@/assets/images/attitude/direction.png'),
        lnglat: require('@/assets/images/attitude/lnglat.png'),
        temp: require('@/assets/images/attitude/temp.png'),
        time: require('@/assets/images/attitude/time.png'),
        level: require('@/assets/images/attitude/level.png'),
        substate: require('@/assets/images/attitude/substate.png'),
        distance: require('@/assets/images/attitude/distance.png'),
        gimbalroll: require('@/assets/images/attitude/gimbalroll.png'),
        gimbalpitch: require('@/assets/images/attitude/gimbalpitch.png'),
        gimbalyaw: require('@/assets/images/attitude/gimbalyaw.png'),
      },
      lazyShow: false,
      loading: null,
      map: null,
      videoMain: true,
      isPlaying: false,
      followUav: false,
      UAVRVPath: null,
      currentTimes: 1,
      timeoutArr: [],
      flightRecord: {},
      records: [],
      events: [],
      operaters: [],
      missionInfo: null,
      currentIndex: 0,
      videoPlayer: null,
      videoTimeAdded: 0,
      missionExecutionInformation: {
        siteName: {
          label: '站点名称',
          value: '未知',
        },
        missionName: {
          label: '任务名称',
          value: '未知',
        },
        missionBatch: {
          label: '任务批次',
          value: '未知',
        },
        UAVStartTime: {
          label: '起飞时间',
          value: '未知',
        },
        UAVEndTime: {
          label: '降落时间',
          value: '未知',
        },
      },
      message: {
        longitude: {
          label: '经度坐标',
          value: '未知',
        },
        latitude: {
          label: '纬度坐标',
          value: '未知',
        },
        altitude: {
          label: '飞行高度',
          value: '未知',
        },
        timestamp: {
          label: '时间',
          value: '未知',
        },
      },
    };
  },
  created() {
    this.missionBatch = this.$route.query.missionBatch;
    this.siteID = this.$route.query.siteID;
  },
  mounted() {
    this.calcaulateWindow();
    window.onresize = () => {
      this.$refs.canvasChart.renderCanvas();
      this.calcaulateWindow();
    };
    this.map = this.$refs.map;
  },

  beforeDestroy() {
     window.onresize =null;
    this.clearLoading();
  },
  watch: {
    currentTimes: {
      handler(n) {
        if (this.videoPlayer) {
          this.videoPlayer.playbackRate = n;
        }
        if (this.isPlaying) {
          this.pausePlay();
          this.continuePlay();
        }
      },
    },
    downloading: {
      handler(n) {
        if (!n && this.axiosSource) {
          this.axiosSource.cancel();
          this.$message('下载已中断！');
        }
      },
    },
    isPlaying: {
      handler(n) {
        if (n) {
          this.continuePlay();
          if (this.videoPlayer) {
            this.videoPlayer.play();
          }
        } else {
          if (this.videoPlayer) {
            this.videoPlayer.pause();
          }
        }
        this.pausePlay();
      },
    },
    currentIndex: {
      handler(n) {
        let records = this.records.slice(0, n + 1);
        this.records3D =records;
        this.map.setRealTimePath(records);
        this.map.setUavMarker(this.UAVID, this.records[n].longitude, this.records[n].latitude, this.records[n].azimuth);
        this.map.setGimbalMarker(this.GIMID, this.records[n].longitude, this.records[n].latitude, this.records[n].gimbalYaw);
        if (this.followUav) {
          this.map.panTo(this.records[n].longitude, this.records[n].latitude);
        }
      },
    },

    events: {
      handler(n) {
        console.log(n);
        if (n.length > 7) {
          this.processWidth = n.length * 50 + 'px';
        } else {
          this.processWidth = '100%';
        }
      },
    },
  },
  methods: {
    handleVideoTimeChange(num) {
      let add = num - this.videoTimeAdded;
      this.videoTimeAdded = num;
      if (this.videoPlayer) {
        let currentSet = this.videoPlayer.currentTime + add;
        let duration = this.videoPlayer.duration; //总时长秒数
        currentSet = currentSet > duration ? duration : currentSet;
        this.videoPlayer.currentTime = currentSet;
      }
    },
    calcaulateWindow() {
      let width = document.body.offsetWidth;
      let height = document.body.offsetHeight - 50;
      let sideWidth = parseInt(width * 0.2);
      let sideWidht169Height = parseInt((sideWidth * 9) / 16);
      let videoWidth = parseInt(width * 0.6);
      let videoHeight = parseInt((videoWidth * 9) / 16);

      this.layout.sideWidth = sideWidth;
      this.layout.videoWidth = videoWidth;
      this.layout.videoHeight = videoHeight;
      this.layout.sideWidht169Height = sideWidht169Height;
      this.layout.fightSize = parseInt((this.layout.sideWidth - 20) / 2);
    },
    // 暂停操作
    pausePlay() {
      this.clearTimeouts();
    },
    // 继续操作
    continuePlay() {
      for (let i = this.currentIndex + 1; i < this.records.length; i++) {
        let delay = 0; // 立即播放
        if (i != this.currentIndex) {
          let timeCount = this.records[i].timestamp - this.records[this.currentIndex].timestamp;
          delay = parseInt(timeCount / this.currentTimes); // 毫秒
        }
        let _this = this;
        this.$nextTick(() => {
          _this.timeoutArr.push(
            setTimeout(function () {
              ++_this.currentIndex;
              if (_this.currentIndex == _this.records.length - 1) {
                _this.isPlaying = false;
              }
            }, delay),
          );
        });
      }
    },

    // 重置操作
    resetAll() {
      this.currentIndex = 0;
      this.currentTimes = 1;
      this.clearTimeouts();
      if (this.videoPlayer) {
        let currentSet = this.videoTimeAdded;
        let duration = this.videoPlayer.duration; //总时长秒数
        currentSet = currentSet > duration ? duration : currentSet;
        this.videoPlayer.currentTime = currentSet;
        this.videoPlayer.pause();
      }
      this.isPlaying = false;
    },
    // 清除所有的定时任务
    clearTimeouts() {
      this.timeoutArr.forEach((t) => {
        clearTimeout(t);
      });
      this.timeoutArr = [];
    },
    //滑块被滑动
    updateBySlider(number) {
      this.currentIndex = number;
      if (this.isPlaying) {
        this.clearTimeouts();
        this.continuePlay();
      }
      this.setVideoPalyedTimeByIndex();
    },
    // 根据当前记录的下标设置视频播放的位置
    setVideoPalyedTimeByIndex() {
      if (this.videoPlayer && this.records.length > 1) {
        let totalSec = (this.records[this.records.length - 1].timestamp - this.records[0].timestamp) / 1000;
        let seconds = (this.records[this.currentIndex].timestamp - this.records[0].timestamp) / 1000;
        let duration = this.videoPlayer.duration; //总时长秒数
        // 方式1：轨迹和视频等比例设置 轨迹总时长：当前位置时间 = 视频总长：视频当前时间
        /*   let currentSet = seconds * duration / totalSec + this.videoTimeAdded
          currentSet = currentSet > duration ? duration : currentSet */

        // 方式2：当前时间直接设置视频当前时间
        let currentSet = seconds;
        this.videoPlayer.currentTime = currentSet + this.videoTimeAdded;
        if (this.isPlaying && duration <= this.videoPlayer.currentTime) {
          this.videoPlayer.play();
        }
      }
    },

    // 控制按钮
    controllerHandler(type) {
      if (this.records.length == 0) {
        this.$message('暂无数据！');
        return;
      }
      switch (type) {
        case 'change':
          // 切换视频和地图
          this.videoMain = !this.videoMain;
          this.$nextTick(() => {
            this.$refs.map.updateSize();
          });
          break;
        case 'download':
          // 下载视频
          if (!this.UAVRVPath) {
            return this.$message('暂无可下载的视频资源！');
          }
          this.downloadVideoWidthProgress();
          break;
        case 'fullScreen':
          this.fullScreenAction();
          break;
        case 'play':
          if (this.isPlaying) {
            this.$message.error('正在播放！');
            return;
          }
          this.isPlaying = true;
          break;
        case 'pause':
          if (!this.isPlaying) {
            this.$message.error('已停止！');
            return;
          }
          this.isPlaying = false;
          break;
        case 'reset':
          this.resetAll();
          break;
        case 'follow':
          this.followUav = !this.followUav;
          this.map.panTo(this.UAVID);
          break;
        case 'one':
          this.currentTimes = 1;
          break;
        case 'two':
          this.currentTimes = 2;
          break;
        case 'four':
          this.currentTimes = 4;
          break;
        case 'ten':
          this.currentTimes = 10;
          break;
      }
    },

    /**
     *
     * @description: 初始化数据
     * @param {*}
     * @return {*}
     */
    async init() {
      this.loading = this.$loading({
        // 声明一个loading对象
        lock: true, // 是否锁屏
        text: '正在加载历史数据...', // 加载动画的文字
        spinner: 'el-icon-loading', // 引入的loading图标
        background: 'rgba(0, 0, 0, 0.7)', // 背景颜色
        target: '.sub-main', // 需要遮罩的区域
        body: false, // 是否挂载在body上
        customClass: 'mask', // 遮罩层新增类名
      });
      await this.getSiteDetail(this.siteID);
      await this.getBatchinfo(this.missionBatch);
    },

    clearLoading() {
      if (this.loading) {
        this.loading.close();
        this.loading = null;
      }
    },
    /**
     * @description: 获取次此次任务详细信息
     * @param {*} missionBatch
     * @return {*}
     */
    async getBatchinfo(missionBatch) {
      let res = await getBatchinfo(missionBatch);
      this.clearLoading();
      let { code, data } = res;
      if(!data.missioninfo){
          data.missioninfo={missionName:''}
      }
      if (!statusInclude(code)) return this.$message.error(res.reason);
      Object.assign(this.flightRecord, data);
      let { UAVRVPath: src, missioninfo, operateRecords, flightRecords, siteName, UAVStartTime, missioninfo:{missionName=''}, missionBatch: missionbatch, UAVEndTime, flightMode = -1, endHiveID = null } = data;

      this.missionExecutionInformation.siteName.value = siteName;
      this.missionExecutionInformation.missionName.value = missionName;
      this.missionExecutionInformation.missionBatch.value = missionbatch;
      this.missionExecutionInformation.UAVStartTime.value = UAVStartTime;
      this.missionExecutionInformation.UAVEndTime.value = UAVEndTime;
      // 不存在视频,地图占满区域
      if (src) {
        this.UAVRVPath = src;
        this.$refs.controlBar.setHasVideo(true);
        let _this = this;
        _this.$nextTick(() => {
          _this.videoPlayer = document.getElementById('video');
          _this.videoPlayer.src = src;
        });
      } else {
        this.lazyShow = true;
      }
      this.missionInfo = missioninfo;
      console.log(missioninfo);
      this.operaters = operateRecords && operateRecords.list ? operateRecords.list : [];
      let records = flightRecords && flightRecords.list ? flightRecords.list : [];
      this.records = this.filterRecords(records);
      this.events = this.calculateEvents();
      // 绘制所有的轨迹
      console.log(this.records);
      if (this.records && this.records.length > 0) {
        this.map.updateUavPath(this.records);
      }

      if (this.missionInfo?.flightParams?.flightPath) {
        let flightPath = this.missionInfo.flightParams.flightPath;
        this.route3D=flightPath
        if (flightPath.length > 0) {
          let hivePos = null;
          if (this.siteInfo && this.siteInfo.siteLocation) {
            hivePos = this.siteInfo.siteLocation;
            this.map.setUavMarker(this.UAVID, this.siteInfo.siteLocation[0], this.siteInfo.siteLocation[1]);
          }
          // 任务航线
          this.map.setMissionPath(flightPath, null, hivePos);
        }
      }
      // flightMode 1:孤岛模式，2：网联模式 ，
      if (flightMode == 2) {
        if (endHiveID) {
          let res = await getSiteDetail(data.endHiveID);
          if (!res.data) return this.$message.error('请求降落机库失败');
          // 添加降落机库
          let siteMode = res.data.siteMode;
          let hiveModel = null;
          if (res.data?.hiveInfo?.hiveModel) {
            hiveModel = res.data.hiveInfo.hiveModel;
          }
          if (res.data.siteLocation) {
            this.map.addEndHiveMarker(siteMode, hiveModel, res.data.siteLocation[0], res.data.siteLocation[1], false);
            this.map.setMissionPath(flightPath, null, hivePos, true);
          }
        } else {
          this.$message.error('缺失降落机库ID');
        }
      } else {
        let hiveModel = null;
        if (this.siteInfo?.hiveInfo?.hiveModel) {
          hiveModel = this.siteInfo.hiveInfo.hiveModel;
        }
        this.map.addEndHiveMarker(this.siteInfo.siteMode, hiveModel, this.siteInfo.siteLocation[0], this.siteInfo.siteLocation[1], true);
        this.map.addEndHiveToMissionPath();
      }
    },

    filterRecords(records) {
      // 204起飞 到 312关机库
      let state204FirstIndex = 0;
      for (let i = 0; i < records.length; i++) {
        if (records[i].subState == 204) {
          state204FirstIndex = i;
          break;
        }
      }
      let records2 = records.slice(state204FirstIndex);
      let state312FirstIndex = records2.length - 1;
      for (let i = 0; i < records2.length; i++) {
        if (records2[i].subState == 312) {
          state312FirstIndex = i;
          break;
        }
      }
      return records2.slice(0, state312FirstIndex + 1);
    },

    downloadVideoWidthProgress() {
      let _this = this;
      // 进度条
      this.downloading = true;
      axios.defaults.headers.common["Authorization"] = getAuthorization()|| '';
      axios.defaults.headers.common["refreshToken"] = getToken()|| '';
      this.axiosSource = axios.CancelToken.source();
      let params = {
        missionBatch: this.missionBatch,
        stId:this.siteID,
        view:'xl_uav'
      };
      axios
        .get(VIDEOWNLOAD, {
          params: params,
          responseType: 'blob',
          onDownloadProgress: function (progressEvent) {
            _this.downloadPercentage = parseInt((progressEvent.loaded / progressEvent.total) * 100);
          },
          cancelToken: this.axiosSource.token,
        })
        .then((res) => {
          _this.downloadPercentage = 100;
          this.axiosSource = null;
          this.downloading = false;
          if (res.data) {
            // 转换一个blob链接
            let u = window.URL.createObjectURL(new Blob([res.data], { type: 'video/mp4' }));
            let a = document.createElement('a');
            //名称
            a.download = _this.missionExecutionInformation.missionName.value;
            a.href = u;
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            a.remove();
            return true;
          }
        });
    },

    /**
     *
     * @description: 获取站点详细信息
     * @param {*} id
     * @return {*}
     */
    async getSiteDetail(id) {
      if (!id) {
        return this.$message.error('站点ID不存在');
      }
      let response = await getSiteDetail(id);
      if (statusInclude(response.code)) {
        this.siteInfo = response.data;
        if (this.siteInfo.siteLocation) {
          let siteMode = this.siteInfo.siteMode;
          let hiveModel = null;
          if (this.siteInfo?.hiveInfo?.hiveModel) {
            hiveModel = this.siteInfo.hiveInfo.hiveModel;
          }
          this.map.addHiveMarker(siteMode, hiveModel, this.siteInfo.siteLocation[0], this.siteInfo.siteLocation[1]);
          this.map.setUavMarker(this.UAVID, this.siteInfo.siteLocation[0], this.siteInfo.siteLocation[1], 0);
        }
      } else {
        this.$message.error(response.reason);
      }
    },
    fullScreenAction() {
      if (this.videoMain) {
        this.$refs.videoRef.webkitRequestFullscreen();
      }
    },

    calculateEvents() {
      let evts = [];
      let keySet = new Set();
      if (this.records.length > 0) {
        for (let i = 0; i < this.records.length; i++) {
          //currentIndex
          let rec = this.records[i];
          let label = parseUAVData.ParseUAVSubState(rec.subState);
          let item = {
            subState: rec.subState,
            label: label ? label : rec.subState,
            value: this.$moment(rec.timestamp).format('YYYY-MM-DD HH:mm:ss'),
          };
          if (!keySet.has(rec.subState)) {
            evts.push(item);
            keySet.add(rec.subState);
          }
        }
      }
      console.log(evts);
      return evts;
    },

    translateDirection(i) {
      let text = '';
      i = i + 180;
      i = parseInt(i);
      if (i < 90) {
        text = `南偏西${i}`;
      } else if (i < 180) {
        text = `北偏西${180 - i}`;
      } else if (i < 270) {
        text = `北偏东${360 - i}`;
      } else if (i < 360) {
        text = `南偏东${360 - i}`;
      }
      return text;
    },
  },

  computed: {
   
          // 无人机的3D位置数据
    uavPosFor3D() {
     let point= this.records[this.currentIndex] ;
        return {
          position: [point.longitude, point.latitude, parseFloat(point.altitude)],
          head: point.UAVYaw,
          pitch: point.UAVPitch,
          roll: point.UAVRoll
        };
    },
    operatersFilter() {
      return this.operaters.map((op) => {
        return {
          time: this.$moment(op.operateTime).format('YYYY-MM-DD HH:mm:ss'),
          content: op.operateContent,
        };
      });
    },

    rightStyle() {
      return `width:${this.layout.sideWidth}px;height:100%`;
    },

    leftStyle() {
      return `width:${this.layout.sideWidth}px;height:100%`;
    },
    topLeftStyle() {
      if (this.videoMain) {
        return `width:${this.layout.sideWidth}px;height:${this.layout.sideWidht169Height}px;`;
      } else {
        return `width:${this.layout.videoWidth}px;height: ${this.layout.videoHeight}px ; left:${this.layout.sideWidth}px ;`;
      }
    },
    topRightStyle() {
      if (this.videoMain) {
        return `width:${this.layout.videoWidth}px;height: ${this.layout.videoHeight}px ; left:${this.layout.sideWidth}px ;`;
      } else {
        return `width:${this.layout.sideWidth}px;height:${this.layout.sideWidht169Height}px;`;
      }
    },
    bottomLeftStyle() {
      return `width:${this.layout.sideWidth}px;height: calc( 100% - ${this.layout.sideWidht169Height}px );`;
    },

    bottomRightStyle() {
      return `width:${this.layout.videoWidth}px;height: calc( 100% - ${this.layout.videoHeight}px ); left:${this.layout.sideWidth}px`;
    },

    space() {
      return 100;
      // return parseInt((360) / this.events.length)
    },
    activeEvent() {
      let active = 0;
      let events = this.events;
      if (events.length > 0 && this.records.length > 0) {
        for (let i = 0; i < events.length; i++) {
          if (this.records[this.currentIndex].subState == events[i].subState) {
            active = i;
            break;
          }
        }
      }
      return active;
    },

    cuurenHeading() {
      let heading = 0;
      if (this.records.length > 0) {
        heading = this.records[this.currentIndex].azimuth;
      }
      return heading;
    },
    currentLongitude() {
      let val = '未知';
      if (this.records.length > 0) {
        val = this.records[this.currentIndex].longitude; 
      }
      return val;
    },
    currentLatitude() {
      let val = '未知';
      if (this.records.length > 0) {
        val = this.records[this.currentIndex].latitude;
      }
      return val;
    },
    currentAltitude() {
      let val = '未知';
      if (this.records.length > 0) {
        val = this.records[this.currentIndex].altitude;
      }
      return val + 'm';
    },
    currentDistanceStart() {
      let val = '未知';
      if (this.records.length > 0) {
        val = this.records[this.currentIndex].distanceStart;
      }
      return val + 'm';
    },
    currentSpeedH() {
      let val = '未知';
      if (this.records.length > 0) {
        val = this.records[this.currentIndex].horizontalSpeed;
      }
      return val + 'm/s';
    },
    currentSpeedV() {
      let val = '未知';
      if (this.records.length > 0) {
        val = this.records[this.currentIndex].verticalSpeed;
      }
      return val + 'm/s';
    },
    currentGPSLevel() {
      let val = '未知';
      if (this.records.length > 0) {
        val = this.records[this.currentIndex].GPSLevel;
      }
      return val;
    },
    currentGPSCount() {
      let val = '未知';
      if (this.records.length > 0) {
        val = this.records[this.currentIndex].GPSCount;
      }
      return val;
    },
    currentUpLink() {
      let val = '未知';
      if (this.records.length > 0) {
        val = this.records[this.currentIndex].upLink;
      }
      return val + '%';
    },
    currentDownLink() {
      let val = '未知';
      if (this.records.length > 0) {
        val = this.records[this.currentIndex].downLink;
      }
      return val + '%';
    },
    currentBattery1Percent() {
      let val = '未知';
      if (this.records.length > 0) {
        val = this.records[this.currentIndex].battery1Percent;
      }
      return val + '%';
    },
    currentBattery1Temperature() {
      let val = '未知';
      if (this.records.length > 0) {
        val = this.records[this.currentIndex].battery1Temperature;
      }
      return val + '℃';
    },
    currentTime() {
      let val = '未知';
      if (this.records.length > 0) {
        val = this.$moment(this.records[this.currentIndex].timestamp).format('YYYY-MM-DD HH:mm:ss');
      }
      return {
        label: '飞行时间',
        value: val,
      };
    },
    cuurentZoom() {
      let zoom = 0;
      if (this.records.length > 0) {
        zoom = this.records[this.currentIndex].zoom ? this.records[this.currentIndex].zoom : 0;
      }
      return zoom;
    },
    cuurentRoll() {
      let roll = 0;
      if (this.records.length > 0) {
        roll = this.records[this.currentIndex].UAVRoll;
      }
      return roll;
    },
    cuurentPitch() {
      let pitch = 0;
      if (this.records.length > 0) {
        pitch = this.records[this.currentIndex].UAVPitch;
      }
      return pitch;
    },
    cuurentGimbalPitch() {
      let pitch = 0;
      if (this.records.length > 0) {
        pitch = this.records[this.currentIndex].gimbalPitch;
      }
      return pitch;
    },
    cuurentGimbalRoll() {
      let roll = 0;
      if (this.records.length > 0) {
        roll = this.records[this.currentIndex].gimbalRoll;
      }
      return roll;
    },
    cuurentGimbaYaw() {
      let pitch = 0;
      if (this.records.length > 0) {
        pitch = this.records[this.currentIndex].gimbalYaw;
      }
      return pitch;
    },
  },
};
</script>

<style lang="scss" scoped>
$bottomHeight: 300px;
$rightWidth: 400px;
$videoWidht: 1120px;
$videoheight: 630px;
$echartHeight: 320px;
$rightHeight16per4: 225px;

::v-deep .el-slider__runway {
  background-color: #2d3035 !important;
}
::v-deep .el-progress__text {
  color: white;
}

::v-deep .el-input__inner {
  -webkit-appearance: none;
  background-color: #313131;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: white;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}

::v-deep .el-input-number__decrease.is-disabled,
.el-input-number__increase.is-disabled {
  color: white;
  cursor: not-allowed;
}

::v-deep .el-input-number__increase {
  position: absolute;
  z-index: 1;
  top: 1px;
  width: 40px;
  height: auto;
  text-align: center;
  background: #313131;
  color: white;
  cursor: pointer;
  font-size: 13px;
}

::v-deep .el-input-number__decrease {
  position: absolute;
  z-index: 1;
  top: 1px;
  width: 40px;
  height: auto;
  text-align: center;
  background: #313131;
  color: white;
  cursor: pointer;
  font-size: 13px;
}

::v-deep .el-input-number--small {
  width: 130px;
  line-height: 38px;
}

::v-deep .el-step__head.is-finish {
  color: white;
  border-color: gainsboro;
}
::v-deep .el-step__title.is-finish {
  color: white;
}

::v-deep .el-step__title {
  font-size: 12px;
  line-height: 38px;
  word-break: keep-all;
}

::v-deep .el-step__title {
  font-size: 12px;
  line-height: 38px;
}
::v-deep .el-step__icon.is-text {
  border-radius: 50%;
  border: 2px solid;
  border-color: gainsboro;
}
::v-deep .el-step__icon-inner {
  display: inline-block;
  user-select: none;
  text-align: center;
  font-weight: 300;
  line-height: 1;
  color: white;
}
::v-deep .el-step__title.is-process {
  font-weight: 700;
  color: #1afa29;
}

::v-deep .is-process {
  .is-text {
    background-color: #1afa29;
    .el-step__icon-inner {
      color: #1afa29;
    }
  }
}

// .down-progress {
//     width: 100%;
//     height: 100%;
//     position: absolute;
//     z-index: 100;
//     display: flex;
//     top: 0;
//     left: 0;
//     background-color: rgb(7 15 156 / 75%);
//     > div {
//         margin: auto;
//     }
// }
.no-data {
  text-align: center;
  color: white;
}
.operater-data {
  width: 100%;
  height: auto;
  max-height: 600px;
  overflow: auto;
  color: white;
  > div {
    height: 40px;
  }
}

.card {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  border: 1px solid #0b137e;
  border-image: linear-gradient(#24952f, #{$containerBg}) 20 20;
  border-radius: 4px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  margin-bottom: 10px;
  .title {
    color: white;
    text-align: center;
    background: #2d3035;
    height: 30px;
    line-height: 30px;
    margin-bottom: 10px;
  }
  .progress {
    overflow-x: auto;
    overflow-y: hidden;
    min-width: 300px;
    padding-left: 15px;
    .progress-main {
    }
  }
  .one-info {
    text-align: left;
    padding-left: 5px;
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    .img {
      width: 26px;
      height: 26px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .text {
      margin-left: 10px;
      line-height: 26px;
      color: white;
    }
  }
  .item-one {
    width: 100%;
    height: 30px;
    display: flex;
    padding: 0 5px;
  }
  .row {
    width: 100%;
    height: auto;
    margin-bottom: 15px;
    margin-left: 5px;
    font-size: 12px;
    display: flex;
    .item {
      display: flex;
      margin: auto;
      padding: 5px;
      background-color: #2d3035;
      width: 40%;
      margin-right: 5%;
      float: left;
      border-radius: 2px;
      .img-info {
        display: flex;
        .img {
          width: 32px;
          height: 32px;
          margin: auto;
          margin-right: 5px;
          .spec {
            width: 42px;
            height: 45px;
            margin-top: -5px;
            margin-left: -5px;
          }
          img {
            width: 100%;
            height: 100%;
          }
        }
        .info {
          display: flex;
          flex-direction: column;
          font-size: 11px;
          > div {
            text-align: left;
            word-break: keep-all;
            margin-bottom: 5px;
          }
        }
      }
    }
    .item-hide {
      background-color: #{$containerBg};
    }
  }

  .event-list-wrap {
    .msg-wrap {
      display: flex;
      flex-direction: column;
      padding: 4px;
      overflow: auto;
    }
    .operating {
      height: 150px;
    }
    .event-area {
      height: calc(100vh - 788px);
      overflow: auto;
    }
  }
}

.no-source {
  width: 100%;
  height: 100%;
  display: flex;
  > div {
    display: flex;
    margin: auto;
    > p {
      font-size: 36px;
      color: white;
    }
  }
}
.video {
  width: 100%;
  height: 100%;
}
.split {
  height: 100%;
  float: left;
}
.flight-text {
  width: 100%;
  height: 30px;
  position: absolute;
  display: flex;
  > div {
    margin: auto;
  }
}
.instruments {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .attitude-title {
    color: #29ea21;
  }
  > div {
    display: flex;
    flex-direction: row;
    margin: 10px auto;
  }
}
div.split:first-child {
  width: 60%;
}
div.split:last-child {
  width: 40%;
}
.map-full {
  width: 100% !important;
}
.framework-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  /*  @media screen and (max-width: 1000px) {
        .page-container {
            display: block !important;
            .left {
                width: 100% !important;
            }
            .right {
                display: none !important;
            }
        }
    } */
  .page-container {
    width: 100%;
    height: 100%;
    padding-bottom: 50px;
    display: flex;
    box-sizing: border-box;
    flex-direction: row;
    overflow: auto;
    background: #{$containerBg};
    .left {
      height: 100%;
      display: flex;
      flex-direction: column;
      z-index: 10;
      position: relative;
      .top-left {
        position: absolute;
        left: 0;
        top: 0;
      }
      .bottom-left {
        position: absolute;
        left: 0;
        bottom: 0;
      }
      .top-right {
        position: absolute;
        top: 0;
        z-index: 100;
        border-left: 1px solid #555;
      }
      .bottom-right {
        border-left: 1px solid #555;
        position: absolute;
        bottom: 0;
        padding: 40px 20px 0px 0px;
        box-sizing: border-box;
        .set-rate {
          position: absolute;
          top: 0;
          width: 100%;
          height: 40px;
          display: flex;
          div {
            display: flex;
            margin: auto;
          }
        }
      }
    }
    .right {
      height: 100%;
      border-top: 1px solid #555;
      border-left: 1px solid #555;
      padding: 10px;
      box-sizing: border-box;
      color: #fff;
      overflow: auto;
      position: absolute;
      right: 0;
    }
  }
}

.three-D {
  width: 30px;
  height: 30px;
  position: absolute;
  border-radius: 50%;
  top: 5px;
  left: 5px;
  z-index: 999999;
  background: #2d3035;
  display: flex;
  cursor: pointer;
  > img {
    margin: auto;
    width: 20px;
    height: 20px;
    margin-bottom: 6px;
  }
}
</style>
