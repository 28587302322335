import { UAV_STATE } from "@/enum/UAV_STATE";

class ParseUAVData {
    ParseUAVSubState(p) {
        let state = UAV_STATE.getDescFromValue(p)
        return state;
    }
    batteryStatus(p) {
        let state = {};
        try {
            state.batteryPercent = (p[0].batteryPercent + p[1].batteryPercent) / 2;
            state.batteryTemperatureLeft = p[0].temperature > 37 ? '左侧电池温度过高' : '正常';
            state.batteryTemperatureRight = p[1].temperature > 37 ? '右侧电池温度过高' : '正常';
        } catch {
            /* 捕获电池为空状态 */
        }
        return state;
    }
}
export default new ParseUAVData();
