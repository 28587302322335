<!--
 * @Author: 篆愁君
 * @Date: 2021-08-26 17:28:27
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-01-10 18:38:25
 * @Description: file content
-->
<template>
  <ul class="right-control-wrapper" id="btn">
    <li class="ctro-item">
      <i class="el-icon-top-left" title="切换" data-type="change"></i>
    </li>

    <!--  <li class="ctro-item">
            <i class="el-icon-video-play" title="播放" data-type='play'></i>
        </li>
        <li>
            <i class="el-icon-video-pause" title="暂停" data-type='pause'></i>
        </li> -->
    <li class="ctro-item">
      <i class="el-icon-full-screen" title="全屏" data-type="fullScreen"></i>
    </li>
    <li class="ctro-item">
      <i :class="videoClass" title="播放/暂停" data-type="play"></i>
    </li>
    <li>
      <i class="el-icon-refresh" title="重置" data-type="reset"></i>
    </li>
    <li>
      <i class="el-icon-aim" title="是否跟随" data-type="follow"></i>
    </li>
    <li class="ctro-item">
      <span :class="{ 'span-text': true, selected: rate == 'one' }" title="1倍速" data-type="rate" data-rate="one">1x</span>
    </li>
    <li class="ctro-item">
      <span :class="{ 'span-text': true, selected: rate == 'two' }" title="2倍速" data-type="rate" data-rate="two">2x</span>
    </li>
    <li class="ctro-item">
      <span :class="{ 'span-text': true, selected: rate == 'four' }" title="4倍速" data-type="rate" data-rate="four">4x</span>
    </li>
    <!--  <li class="ctro-item">
            <span :class="{'span-text':true,'selected':rate=='ten'}" title="10倍速" data-type='ten' style="padding: 5px 6px;">10x</span>
        </li> -->

    <li class="ctro-item" v-if="!noVideo">
      <i class="el-icon-download" title="下载视频" data-type="download"></i>
    </li>

    <li class="ctro-item ctro-item-large" v-if="!noVideo">
      <div>
        <div>设置视频步进 (秒) ：</div>
        <div>
          <el-input-number size="small" @change="handleVideoTimeChange" :min="0" :max="50" v-model="num" :precision="1" :step="0.5" step-strictly label="设置视频步进"></el-input-number>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  naem: 'CtrlItem',
  props: {
    playing: false,
  },

  data() {
    return { noVideo: true, num: 0, rate: 'one' };
  },
  mounted() {
    let _this = this;
    document.getElementById('btn').addEventListener('click', function(e) {
      let dataset = e.target.dataset;
      if (dataset && dataset.type) {
        let type = dataset.type;
        if (type == 'play') {
          if (_this.playing) {
            _this.$emit('controller', 'pause');
          } else {
            _this.$emit('controller', 'play');
          }
        } else if (type == 'rate') {
          _this.rate = dataset.rate;
          _this.$emit('controller', dataset.rate);
        } else {
          _this.$emit('controller', type);
        }
      }
    });
  },

  computed: {
    videoClass() {
      if (this.playing) {
        return 'el-icon-video-pause';
      }
      return 'el-icon-video-play';
    },
  },
  methods: {
    setHasVideo(t) {
      this.noVideo = !t;
    },
    handleVideoTimeChange(e) {
      this.$emit('videoTimeChange', e);
    },
  },
};
</script>

<style lang="scss" scoped>
.ctro-item-large {
  width: auto !important;
  > div {
    width: 248px;
    font-size: 12px;
    color: white;
    margin-left: 20px;
    display: flex;
  }
}
.right-control-wrapper {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #313131;
  font-size: 18px;
  i:hover,
  .span-text:hover {
    opacity: 0.75;
  }
  i:active,
  .span-text:active {
    font-size: 14px;
  }
  li {
    height: 40px;
    width: 40px;
    line-height: 40px;
    text-align: center;
    > i,
    .span-text {
      padding: 5px 10px;
      background: #{$menuHover};
      color: #fff;
      line-height: 21px;
      cursor: pointer;
      font-size: 16px;
      &.selected {
        color: #3484ff;
        font-weight: 600;
      }
    }
  }
}
</style>
