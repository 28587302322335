import request from "@/utils/request"
import { base_url } from '@/utils/BASE_URL';
const server = base_url.server;

function getFlightRecord(page, size, name = '') {
  const url = `${server}/dataCenter/qcp/flightschedules/list?page=${page}&size=${size}`
  return request({
    url: Boolean(name) ? url + `&missionName=${name}` : url,
    method: 'GET'
  })
}

//根据批次号获取无人机飞行相关信息
function getBatchinfo(missionBatch = '') {
  return request({
    url: `${server}/${base_url.core}/flightschedules/batchinfo?missionBatch=${missionBatch}`,
    method: 'GET'
  })
}

export {
  getFlightRecord,
  getBatchinfo
}